import React from "react";
import { motion } from "framer-motion";

// Styles
import * as styles from "../styles/pages/common.module.css";

const Card = ({
  title,
  titleClassName,
  description,
  descriptionClassName,
  content,
  contentClassName,
  cardClassName,
}) => {
  return (
    <div className={cardClassName ?? styles.card}>
      <motion.h2 className={titleClassName ?? styles.card__title}>
        {title}
      </motion.h2>
      <motion.p className={descriptionClassName ?? styles.card__description}>
        {description}
      </motion.p>
      <motion.div className={contentClassName}>{content}</motion.div>
    </div>
  );
};

export default Card;
