import * as React from "react";
import { graphql } from "gatsby";
import { getImage, StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  useTranslation,
  Trans,
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import { window } from "browser-monads";
import { ThreeDots } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import styled from "styled-components";

// Hooks
import useScreenSize from "../../hooks/useScreenSizes";

// Components
import Seo from "../../components/SEO";
import Layout from "../../components/Layout";
import HeroGradient from "../../components/HeroGradient";
import Card from "../../components/Card";
import { FeaturesSection } from "../../templates/product-page";
import MouseScrollAnimated from "../../components/MouseScrollAnimated";
import BusinessPlanCardContent from "./BusinessPlanCardContent";

// Assets
import useCasesHero from "../../images/usecases/usecase-hero.png";
import useCasesHeroMobile from "../../images/usecases/usecase-hero-mobile.png";
import ZealToolsImage from '../../images/zeal-tools.svg';
import ZealToolsImageMobile from '../../images/zeal-tools-mobile.svg';
import SuccessIcon from "../../images/icons/contact-success.svg";
import basketLevel from "../../images/usecases/6.png";
// Styles
import * as styles from "../../styles/pages/use-cases.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import { api } from "../../utils/api";

const MotionLink = motion(Link);

const HeroSection = () => {
  const { t } = useTranslation();
  const { isLG } = useScreenSize();

  return (
    <section>
      <motion.div className={styles.heroSection__wrapper}>
        <HeroGradient />
        <div className="max-w-[1214px] mx-auto relative">
          <motion.div
            {...revealContainerWithChildrenProps}
            className={`${styles.heroSection__content} sectionContainer`}
          >
            <motion.div
              variants={revealVariant}
              className={styles.heroSection__skuWrapper}
            >
              <motion.h1
                variants={revealVariant}
                className={styles.heroSection__title}
              >
                <Trans
                  i18nKey="useCases.heroSection.title"
                  values={{
                    skus: t("useCases.heroSection.skus"),
                    insight: t("useCases.heroSection.insight"),
                  }}
                  components={[<span />]}
                />
              </motion.h1>
              <motion.p
                variants={revealVariant}
                className={styles.heroSection__description}
              >
                {t("useCases.heroSection.description")}
              </motion.p>
              <motion.div
                variants={revealVariant}
                className={styles.heroSection__button__wrapper}
              >
                <MotionLink
                  to="/use-cases/fmcgs#contact-us"
                  className={`${styles.heroSection__button} button button--primary`}
                >
                  {t("useCases.heroSection.get-early-access")}
                </MotionLink>
              </motion.div>
            </motion.div>
            <motion.div
              variants={revealVariant}
              className={`${styles.heroSection__backgroundImg_wrapper}`}
            >
              <motion.img
                alt="Zeal Pay"
                src={isLG ? useCasesHero : useCasesHeroMobile}
                formats={["auto", "webp", "avif"]}
                className={styles.heroSection__backgroundImg}
              />
            </motion.div>
          </motion.div>
        </div>
        <MouseScrollAnimated />
      </motion.div>
    </section>
  );
};

const GainDeeperUnderstanding = () => {
  const { t } = useTranslation();
  const { isMobile, isXL } = useScreenSize();

  return (
		<div className="bg-white">
			<motion.div
				className={`${styles.gainDeepUnderstandingWrapper} sectionContainer`}
			>
				<motion.div className={styles.gainDeepUnderstanding__contentWrapper}>
					<motion.h2
						{...revealContainerProps}
						variants={revealVariant}
						className={styles.gainDeepUnderstanding__title}
					>
						{t('useCases.gainDeeperUnderstanding.title')}
					</motion.h2>
					<motion.p
						{...revealContainerProps}
						variants={revealVariant}
						className={styles.gainDeepUnderstanding__description}
					>
						{t(
							`useCases.gainDeeperUnderstanding.description-${
								isMobile ? 'mobile' : 'desktop'
							}`
						)}
					</motion.p>
				</motion.div>
				<motion.div
					variants={revealVariant}
					className={`inline-block ${styles.gainDeepUnderstanding__img__wrapper}`}
				>
					<GatsbyImage
						src={isMobile ? ZealToolsImageMobile : ZealToolsImage}
						alt="zeal-tools"
						className={`${!isXL ? 'max-w-full' : 'max-w-[992px]'}`}
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

const featuresSection = (t, fmcgsImages) => ({
  title: t("useCases.whyChoose.title"),
  description: "",
  features: [
    {
      title: t("useCases.whyChoose.competitiveEdge.title"),
      description: t("useCases.whyChoose.competitiveEdge.description"),
      image: getImage(fmcgsImages.edges[0].node.childImageSharp),
    },
    {
      title: t("useCases.whyChoose.discountedPricing.title"),
      description: t("useCases.whyChoose.discountedPricing.description"),
      image: getImage(fmcgsImages.edges[1].node.childImageSharp),
    },
    {
      title: t("useCases.whyChoose.indepthData.title"),
      description: t("useCases.whyChoose.indepthData.description"),
      image: getImage(fmcgsImages.edges[2].node.childImageSharp),
    },
    {
      title: t("useCases.whyChoose.flexibleTracking.title"),
      description: t("useCases.whyChoose.flexibleTracking.description"),
      image: getImage(fmcgsImages.edges[3].node.childImageSharp),
    },
  ],
  motionSvg: false,
});

const BusinessPlans = () => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.businessPlans} sectionContainer`}>
      <motion.h2
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.businessPlans__title}
      >
        {t("useCases.businessPlans.title")}
      </motion.h2>
      <motion.p
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.businessPlans__description}
      >
        {t("useCases.businessPlans.description")}
      </motion.p>
      <motion.div className={styles.businessPlans__cards}>
        <Card
          title={t("useCases.businessPlans.starterPlan.title")}
          description={t("useCases.businessPlans.starterPlan.description")}
          content={
            <BusinessPlanCardContent
              price={3500}
              features={[
                "Deposit: $10,000 (credited against future subscription fees)",
                "Pre-launch Subscription Fee: $3,500/month (30% discount on launch price)",
                "Launch Subscription Fee: $5,000/month",
              ]}
            />
          }
        />
        <Card
          title={t("useCases.businessPlans.growthPlan.title")}
          description={t("useCases.businessPlans.growthPlan.description")}
          content={
            <BusinessPlanCardContent
              price={3500}
              features={[
                "Deposit: $20,000 (credited against future subscription fees)",
                "Pre-launch Subscription Fee: $7,500/month (30% discount on launch price)",
                "Launch Subscription Fee: $10,500/month",
              ]}
            />
          }
        />
        <Card
          title={t("useCases.businessPlans.enterprisePlan.title")}
          description={t("useCases.businessPlans.enterprisePlan.description")}
          content={
            <BusinessPlanCardContent
              price={3500}
              features={[
                "Deposit: $40,000 (credited against future subscription fees)",
                "Pre-launch Subscription Fee: $15,000/month (30% discount on launch price)",
                "Launch Subscription Fee: $21,000/month",
              ]}
            />
          }
        />
      </motion.div>
      <MotionLink
        to="/use-cases/fmcgs#contact-us"
        className={`${styles.businessPlans__button} button button--primary`}
      >
        {t("useCases.heroSection.get-early-access")}
      </MotionLink>
    </section>
  );
};
const Features = ({ fmcgsImages }) => {
  const { t } = useTranslation();
  const featuresSectionProps = featuresSection(t, fmcgsImages);
  return (
    <motion.div className={styles.features}>
      <FeaturesSection {...featuresSectionProps} />
    </motion.div>
  );
};
const BasketLevel = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  return (
		<motion.div className={`${styles.basketLevelWrapper} sectionContainer`}>
			<Card
				cardClassName={styles.basketLevel__card}
				title={
					<div>
						{isMobile && (
							<StaticImage
								src={'../../images/usecases/fmcgs/4-flexible-tracking.png'}
								alt="Leaverage Basket-Level Data"
							/>
						)}
						{t('useCases.businessPlans.basketLevel.title')}
					</div>
				}
				titleClassName={styles.basketLevel__title}
				contentClassName={styles.basketLevel__container}
				content={
					<>
						<motion.div className={styles.basketLevel__contentWrapper}>
							<motion.p className={styles.basketLevel__content}>
								{t('useCases.businessPlans.basketLevel.description')}
							</motion.p>

							<MotionLink
								to="/use-cases/fmcgs#contact-us"
								className={`${styles.basketLevel__button} button button--primary`}
							>
								{t('useCases.businessPlans.basketLevel.tryNot')}
							</MotionLink>
						</motion.div>

						{!isMobile && (
							<motion.div className={styles.basketLevel__image}>
								<StaticImage src={'../../images/usecases/6.png'} />
							</motion.div>
						)}
					</>
				}
			/>
		</motion.div>
	);
};

const ReadyToGetStarted = () => {
  const { t, language } = useI18next();

  const { isMobile } = useScreenSize();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    //Waiting for the new api
    const response = await fetch(`${api}/v3/fmcg`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        name: data.fullName,
        phone: data.phone,
        email: data.email,
        company_name: data.company,
      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_FMCG",
          formPosition: "body",
        });
      }
      setIsFormSubmitted(true);
    } else {
      try {
        const res = await response?.json();
        const errorMessages = Object.values(res?.errors || {});

        toast.error(
          errorMessages?.[0] || res?.message || "Something went wrong"
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
    setIsLoading(false);
  };
  if (isFormSubmitted) {
    return (
      <section
        className={`${styles.formSuccessSection} sectionContainer`}
        id="contact-us"
      >
        <div className={styles.readyToGetStartedWrapper}>
          <div className={styles.formSuccessSection__icon}>
            <SuccessIcon />
          </div>
          <h2 className={`${styles.formSuccessSection__title} !text-black`}>
            {t("contact.form.success.title")}
          </h2>
          <p
            className={`${styles.formSuccessSection__description} !text-black`}
          >
            {t("contact.form.success.description")}
          </p>
          <Link to="/" className="button button--secondary">
            {t("contact.form.success.button")}
          </Link>
        </div>
      </section>
    );
  }

  return (
    <motion.div
      className={`sectionContainer ${styles.readyToGetStartedContainer}`}
      id="contact-us"
    >
      <motion.div
        id="contact-us"
        name="contact-us"
        className={styles.readyToGetStartedWrapper}
      >
        <motion.h2 className={styles.readyToGetStarted__title}>
          {t("useCases.businessPlans.readyToGetStarted.title")}
        </motion.h2>
        <motion.h2 className={styles.readyToGetStarted__description}>
          {t("useCases.businessPlans.readyToGetStarted.description")}
        </motion.h2>
        <motion.div className={styles.readyToGetStarted__contentWrapper}>
          {!isMobile && (
            <StaticImage
              src={"../../images/Contact-us.svg"}
              alt="contact-us"
              className="max-w-[380px] lg:max-w-none lg:min-w-[340px] lg:w-1/2"
            />
          )}
          <motion.div className={styles.contactUsForm}>
            <motion.form onSubmit={handleSubmit(onSubmit)}>
              <motion.div variants={revealVariant}>
                <label htmlFor="fullName">
                  {t("useCases.businessPlans.readyToGetStarted.fullName.title")}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder={t(
                    "useCases.businessPlans.readyToGetStarted.fullName.placeholder"
                  )}
                  className={`input  ${errors.fullName ? "error" : ""}`}
                  {...register("fullName", { required: true })}
                />
                {errors.fullName && (
                  <span className={styles.formSection__form__error}>
                    {errors.fullName.type === "required"
                      ? t("contact.formSection.form.error.required")
                      : t("contact.formSection.form.error.validation", {
                          field: t(
                            "contact.formSection.form.fullName.label"
                          ).toLowerCase(),
                        })}
                  </span>
                )}
              </motion.div>
              <motion.div>
                <label htmlFor="phone">
                  {t(
                    "useCases.businessPlans.readyToGetStarted.mobileNumber.title"
                  )}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  placeholder={t(
                    "useCases.businessPlans.readyToGetStarted.mobileNumber.placeholder"
                  )}
                  id="phone"
                  className={`input  ${errors.phone ? "error" : ""}`}
                  {...register("phone", {
                    required: true,
                    pattern:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                  })}
                />
                {errors.phone && (
                  <span className={styles.formSection__form__error}>
                    {errors.phone.type === "required" &&
                      t("contact.formSection.form.error.required", {
                        field: t(
                          "contact.formSection.form.phone.label"
                        ).toLowerCase(),
                      })}
                    {errors.phone.type === "pattern" &&
                      t("contact.formSection.form.error.validation", {
                        field: t(
                          "contact.formSection.form.phone.label"
                        ).toLowerCase(),
                      })}
                  </span>
                )}
              </motion.div>
              <motion.div>
                <label htmlFor="email">
                  {t("useCases.businessPlans.readyToGetStarted.email.title")}
                  <span>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder={t(
                    "useCases.businessPlans.readyToGetStarted.email.placeholder"
                  )}
                  className={`input  ${errors.email ? "error" : ""}`}
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className={styles.formSection__form__error}>
                    {errors.email.type === "required"
                      ? t("contact.formSection.form.error.required")
                      : t("contact.formSection.form.error.validation", {
                          field: t(
                            "contact.formSection.form.email.label"
                          ).toLowerCase(),
                        })}
                  </span>
                )}
              </motion.div>
              <motion.div>
                <label htmlFor="companyName">
                  {t(
                    "useCases.businessPlans.readyToGetStarted.companyName.title"
                  )}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  name="companyName"
                  placeholder={t(
                    "useCases.businessPlans.readyToGetStarted.companyName.placeholder"
                  )}
                  id="company"
                  className={`input  ${errors.company ? "error" : ""}`}
                  {...register("company", { required: true })}
                />
                {errors.company && (
                  <span className={styles.formSection__form__error}>
                    {errors.company.type === "required"
                      ? t("contact.formSection.form.error.required")
                      : t("contact.formSection.form.error.validation", {
                          field: t(
                            "contact.formSection.form.company.label"
                          ).toLowerCase(),
                        })}
                  </span>
                )}
              </motion.div>
              <motion.button
                variants={revealVariant}
                type="submit"
                className={`${styles.contactUsForm__button} button`}
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <ThreeDots
                      height="28"
                      width="28"
                      color="#fff"
                      ariaLabel="loading"
                      radius="3"
                      visible={true}
                    />
                  </div>
                ) : (
                  t("useCases.businessPlans.readyToGetStarted.submit")
                )}
              </motion.button>
            </motion.form>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const FMCGs = ({ data: { fmcgsImages } }) => {
  return (
    <Layout>
      <HeroSection />
      <GainDeeperUnderstanding />
      <Features fmcgsImages={fmcgsImages} />
      <BusinessPlans />
      <BasketLevel />
      <ReadyToGetStarted />
      <Toaster />
    </Layout>
  );
};

export default FMCGs;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "fmcgs", "contact"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    fmcgsImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { regex: "/usecases/fmcgs/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, PNG])
          }
        }
      }
    }
  }
`;

export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";

  return <Seo isArabic={isArabic} title={"FMCGS"} />;
};

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
